import React, { FC } from 'react';

import logomark from '../../../assets/branding/rmg-logo-mark.png';
import logoMain from '../../../assets/branding/rmg-logo-with-iso.png';

export interface LogoProps {
    type?: 'main' | 'mark';
}

const logoSrc = {
    main: logoMain,
    mark: logomark
};

const Logo: FC<LogoProps> = ({ type = 'main' }) => {
    return (
        <div>
            <img
                src={logoSrc[type]}
                alt="RMG Medical Supply, Inc Logo"
                className="h-6 min-h-5"
            />
        </div>
    );
};

export default Logo;
