import clsx from 'clsx';
import React, { forwardRef } from 'react';

import './styles.css';

export interface MenuItemProps extends React.LiHTMLAttributes<HTMLLIElement> {
    navMenu?: boolean;
    withSub?: boolean;
}

const MenuItem = forwardRef<HTMLLIElement, MenuItemProps>(
    ({ children, className, navMenu, withSub, ...props }, ref) => {
        const navMenuClasses = clsx(
            'mb-4',
            'l-sm:mb-0 l-sm:mr-6 l-sm:last:mr-0',
        );

        return (
            <li
                className={clsx(
                    { [navMenuClasses]: navMenu },
                    { 'nav-menu-with-sub relative': withSub },
                    className,
                )}
                ref={ref}
                {...props}>
                {children}
            </li>
        );
    },
);

MenuItem.defaultProps = {
    navMenu: false,
    withSub: false,
};

export default MenuItem;
