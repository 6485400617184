import React, { FC } from 'react';
import clsx from 'clsx';

export interface HeadingProps extends React.HTMLAttributes<HTMLOrSVGElement> {
    tagName?: keyof JSX.IntrinsicElements;
    className?: string;
    color?: 'white' | 'dark-gray';
}

const Heading: FC<HeadingProps> = ({
    children,
    className,
    color,
    tagName,
    ...props
}) => {
    const Tag = tagName as keyof JSX.IntrinsicElements;
    const classes = clsx(`text-${color}`, className);

    return (
        <Tag {...props} className={classes}>
            {children}
        </Tag>
    );
};

Heading.defaultProps = {
    tagName: 'h2',
    color: 'dark-gray'
};

export default Heading;
