import React from 'react';
import clsx from 'clsx';

export interface ParagraphProps
    extends React.HTMLAttributes<HTMLParagraphElement> {
    body?: boolean;
    color?: 'white' | 'dark-gray';
}

const Paragraph = React.forwardRef<HTMLParagraphElement, ParagraphProps>(
    ({ body, children, className, color, ...props }, ref) => {
        const classes = clsx(
            { 'text-opacity-80 leading-loose': body },
            `text-${color}`,
            className
        );

        return (
            <p className={classes} ref={ref} {...props}>
                {children}
            </p>
        );
    }
);

Paragraph.defaultProps = {
    body: true,
    color: 'dark-gray'
};

export default Paragraph;
