import { useLocation } from '@reach/router';
import clsx from 'clsx';
import { Link } from 'gatsby';
import React, { FC, useEffect, useRef } from 'react';

import { Grid } from '../../components/layout/grid';
import { BurgerButton } from '../../components/navigation/burger-button';
import { Button } from '../../components/navigation/button';
import { MegaMenu } from '../../components/navigation/mega-menu';
import { Menu } from '../../components/navigation/menu';
import { MenuItem } from '../../components/navigation/menu-item';
import { NavLink } from '../../components/navigation/nav-link';
import { Navbar } from '../../components/navigation/navbar';
import SvgIcon from '../../components/svg-icon/svg-icon';
import { Paragraph } from '../../components/typography/paragraph';
import { Logo } from '../logo';

export interface HeaderProps extends React.HTMLAttributes<HTMLElement> {
    offset?: boolean;
    routes: any;
}

const Header: FC<HeaderProps> = ({ className, offset, routes, ...props }) => {
    const burgerButtonRef = useRef<HTMLButtonElement>(null);
    const headerRef = useRef<HTMLElement>(null);
    const menuRef = useRef<HTMLUListElement>(null);
    const navbarRef = useRef<HTMLElement>(null);
    const { pathname } = useLocation();

    useEffect(() => {
        const l_sm = window.matchMedia('(min-width: 1281px)');
        const burgerButton = burgerButtonRef.current;
        const header = headerRef.current;
        const menu = menuRef.current!;
        const navbar = navbarRef.current;
        const itemWithSub = document.querySelectorAll('.nav-menu-with-sub');
        const body = document.querySelector<HTMLElement>('body')!;

        //burger-menu resize
        const handleMobileMenuTop = () => {
            if (menu && navbar) {
                menu.style.top = `${navbar.offsetHeight - 1}px`;
            }
        };

        if (!l_sm.matches) {
            handleMobileMenuTop();
            window.addEventListener('resize', handleMobileMenuTop);
        }

        l_sm.onchange = (e) => {
            if (e.matches) {
                window.removeEventListener('resize', handleMobileMenuTop);
            } else {
                window.addEventListener('resize', handleMobileMenuTop);
            }
        };

        //header offset
        const handleHeaderOffset = () => {
            if (offset && header && navbar) {
                header.style.marginTop = `${navbar.offsetHeight - 1}px`;
            }
        };

        handleHeaderOffset();

        window.addEventListener('resize', handleHeaderOffset);

        //burger-btn
        if (burgerButton) {
            burgerButton.addEventListener('click', () => {
                burgerButton.classList.toggle('active');
                menu.classList.toggle('active');
                body.classList.toggle('overflow-y-hidden');
            });
        }

        //mobile sub
        itemWithSub.forEach((item) => {
            item.addEventListener('click', () => {
                const subMenu = item.querySelector<HTMLElement>('.mega-menu')!;

                if (subMenu.style.maxHeight) {
                    item.classList.remove('active');
                    // @ts-ignore
                    subMenu.style.maxHeight = null;
                } else {
                    subMenu.style.maxHeight = subMenu.scrollHeight + 'px';
                    item.classList.add('active');
                }
            });
        });
    }, []);

    useEffect(() => {
        const body = document.querySelector<HTMLElement>('body')!;
        body.classList.remove('overflow-y-hidden');
    }, [pathname]);

    return (
        <header
            className={clsx('bg-primary header', className)}
            ref={headerRef}
            {...props}>
            <Navbar ref={navbarRef}>
                <Link to="/">
                    <Logo />
                </Link>
                <Menu ref={menuRef} navMenu={true}>
                    {routes.map(
                        (item: {
                            id: string;
                            title: string;
                            slug: string;
                            subMenu: [
                                {
                                    id: string;
                                    title: string;
                                    subCategories: [
                                        {
                                            id: string;
                                            title: string;
                                            slug: string;
                                        }
                                    ];
                                }
                            ];
                        }) => {
                            if (item.slug !== '/') {
                                return (
                                    <MenuItem
                                        key={item.id}
                                        navMenu={true}
                                        withSub={!!item.subMenu}>
                                        <NavLink
                                            to={item.slug || ''}
                                            withSub={!!item.subMenu}
                                            headerLink={true}>
                                            {item.title}
                                        </NavLink>

                                        {!!item.subMenu && (
                                            <MegaMenu
                                                className={clsx(
                                                    'l-sm:min-w-1080px'
                                                )}>
                                                <Grid
                                                    container
                                                    base={false}
                                                    defaultGap={false}
                                                    className={clsx(
                                                        'bg-primary-300 rounded mt-4 p-7',
                                                        'l-sm:mt-0 l-sm:grid l-sm:grid-cols-3 l-sm:gap-x-10 l-sm:gap-y-8'
                                                    )}>
                                                    {item.subMenu.map(
                                                        (subItem) => {
                                                            return (
                                                                <Grid
                                                                    item
                                                                    key={
                                                                        subItem.id
                                                                    }
                                                                    className={clsx(
                                                                        'mb-10 last:mb-0',
                                                                        'l-sm:mb-0',

                                                                        {
                                                                            'l-sm:col-span-full':
                                                                                subItem.title ===
                                                                                'Medical Disposables'
                                                                        }
                                                                    )}>
                                                                    <Grid
                                                                        container
                                                                        base={
                                                                            false
                                                                        }
                                                                        defaultGap={
                                                                            false
                                                                        }
                                                                        className={clsx(
                                                                            'grid grid-cols-1fr-auto gap-x-3 items-center pb-3 mb-3',
                                                                            ' border-b border-white border-opacity-20'
                                                                        )}>
                                                                        <Paragraph
                                                                            body={
                                                                                false
                                                                            }
                                                                            color="white"
                                                                            className={clsx(
                                                                                'text-sm font-normal'
                                                                            )}>
                                                                            {
                                                                                subItem.title
                                                                            }
                                                                        </Paragraph>
                                                                        <SvgIcon
                                                                            icon="arrow-right-circle"
                                                                            color="white"
                                                                            className="transform rotate-90"
                                                                        />
                                                                    </Grid>

                                                                    <Menu
                                                                        className={clsx(
                                                                            {
                                                                                'l-sm:col-count-3 l-sm:col-gap-10':
                                                                                    subItem.title ===
                                                                                    'Medical Disposables'
                                                                            }
                                                                        )}>
                                                                        {subItem.subCategories.map(
                                                                            (
                                                                                subCategory
                                                                            ) => {
                                                                                return (
                                                                                    <MenuItem
                                                                                        className="mb-3 last:mb-0 l-sm:min-w-28ch"
                                                                                        key={
                                                                                            subCategory.id
                                                                                        }>
                                                                                        <NavLink
                                                                                            sub={
                                                                                                true
                                                                                            }
                                                                                            to={
                                                                                                !!subCategory.slug
                                                                                                    ? `/products${subCategory.slug}`
                                                                                                    : ''
                                                                                            }>
                                                                                            {
                                                                                                subCategory.title
                                                                                            }
                                                                                        </NavLink>
                                                                                    </MenuItem>
                                                                                );
                                                                            }
                                                                        )}
                                                                    </Menu>
                                                                </Grid>
                                                            );
                                                        }
                                                    )}
                                                </Grid>
                                            </MegaMenu>
                                        )}
                                    </MenuItem>
                                );
                            }
                        }
                    )}
                    <MenuItem>
                        <Button anchor="product-demo">Product Demo</Button>
                    </MenuItem>
                </Menu>

                <BurgerButton ref={burgerButtonRef} />
            </Navbar>
        </header>
    );
};

Header.defaultProps = {
    offset: true
};

export default Header;
