import IcoMoon from 'icomoon-react';
import React from 'react';

import iconSet from '../../../assets/icons/selection.json';

const SvgIcon: React.FC<{
    color?: string;
    size?: string | number;
    icon: string;
    className?: string;
}> = (props) => {
    const { color, size, icon, className } = props;
    return (
        <IcoMoon
            className={className}
            iconSet={iconSet}
            color={color}
            size={size}
            icon={icon}
        />
    );
};

SvgIcon.defaultProps = {
    className: '',
    size: 20,
};
export default SvgIcon;
