import clsx from 'clsx';
import { Link as GatsbyLink } from 'gatsby';
import React, { FC } from 'react';

import SvgIcon from '../../svg-icon/svg-icon';
import './styles.css';

export interface NavLinkProps
    extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
    activeClassName?: string;
    color?: 'white' | 'dark-gray';
    headerLink?: boolean;
    href?: string;
    partiallyActive?: any;
    sub?: boolean;
    to?: string;
    withSub?: boolean;
}

const NavLink: FC<NavLinkProps> = ({
    activeClassName,
    children,
    className,
    color,
    headerLink,
    partiallyActive,
    sub,
    to,
    withSub,
    ...props
}) => {
    //@ts-ignore
    const internal = /^\/(?!\/)/.test(to);
    if (internal) {
        return (
            <GatsbyLink
                //@ts-ignore
                to={to}
                className={clsx(
                    `flex items-center text-${color} cursor-pointer`,
                    {
                        'nav-link': !headerLink,
                        'nav-header-link': headerLink,
                        'nav-link-with-sub': withSub,
                        'text-xs font-medium uppercase tracking-wider': headerLink,
                        'text-sm text-opacity-80': sub
                    },
                    className
                )}
                activeClassName={activeClassName}
                partiallyActive={partiallyActive}
                {...props}>
                {children}
            </GatsbyLink>
        );
    }
    return (
        <a
            href={to}
            {...props}
            className={clsx(
                `flex items-center text-${color} cursor-pointer`,
                {
                    'nav-link': !headerLink,
                    'nav-header-link': headerLink,
                    'nav-link-with-sub': withSub,
                    'text-xs font-medium uppercase tracking-wider': headerLink,
                    'text-sm text-opacity-80': sub
                },
                className
            )}
            onClick={(e) => to === '' && e.preventDefault()}>
            {children}
            {withSub && (
                <SvgIcon
                    icon="arrow-right-circle"
                    size={20}
                    className="ml-1.5 fill-current"
                />
            )}
        </a>
    );
};

NavLink.defaultProps = {
    activeClassName: 'active',
    color: 'white',
    headerLink: false,
    sub: false,
    withSub: false
};

export default NavLink;
