import clsx from 'clsx';
import React from 'react';

export interface SectionProps extends React.HTMLAttributes<HTMLElement> {
    base?: boolean;
    framed?: boolean;
}

const Section = React.forwardRef<HTMLElement, SectionProps>(
    ({ base, children, className, framed, ...props }, ref) => {
        const classes = clsx(
            { 'py-16 t-lg:py-20': base, 't-sm:mx-3 rounded-md': framed },
            className
        );

        return (
            <section className={classes} ref={ref} {...props}>
                {children}
            </section>
        );
    }
);

Section.defaultProps = {
    base: true,
    framed: false
};

export default Section;
