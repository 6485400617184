import clsx from 'clsx';
import React, { forwardRef } from 'react';
import './styles.css';

export interface MenuProps extends React.HTMLAttributes<HTMLUListElement> {
    navMenu?: boolean;
}

const Menu = forwardRef<HTMLUListElement, MenuProps>(
    ({ children, className, navMenu, ...props }, ref) => {
        const navMenuClasses = clsx(
            'burger-nav',
            'flex flex-col p-6 fixed inset-0 bg-primary overflow-y-auto',
            'm-lg:left-auto m-lg:max-w-25',
            'l-sm:flex-row l-sm:items-center l-sm:static l-sm:p-0 l-sm:overflow-y-visible'
        );

        return (
            <ul
                className={clsx({ [navMenuClasses]: navMenu }, className)}
                ref={ref}
                {...props}
            >
                {children}
            </ul>
        );
    }
);

Menu.defaultProps = {
    navMenu: false
};

export default Menu;
