import React from 'react';
import clsx from 'clsx';

export interface ContainerProps extends React.HTMLAttributes<HTMLDivElement> {}

const Container = React.forwardRef<HTMLDivElement, ContainerProps>(
    ({ children, className, ...props }, ref) => {
        const classes = clsx(
            `container mx-auto px-6 m-lg:px-0 t-sm:px-10 d-md:px-0`,
            className
        );

        return (
            <div className={classes} ref={ref} {...props}>
                {children}
            </div>
        );
    }
);

Container.defaultProps = {};

export default Container;
