import { useLocation } from '@reach/router';
import { graphql, useStaticQuery } from 'gatsby';
import React, { FC } from 'react';
import Helmet from 'react-helmet';

import favicon from '../../../assets/images/favicon.png';

interface SEOProps {
    title?: string;
    description?: string;
    image?: string;
}

const SEO: FC<SEOProps> = ({ title, description, image }) => {
    const { site } = useStaticQuery(query);
    const { pathname } = useLocation();

    const {
        defaultDescription,
        defaultTitle,
        titleTemplate,
        url
    } = site.siteMetadata;

    const seo = {
        title: title || defaultTitle,
        description: description || defaultDescription,
        image: image,
        url: `${url}${pathname}`
    };

    return (
        <Helmet title={seo.title} titleTemplate={titleTemplate}>
            <link rel="icon" href={favicon} />

            <meta name="description" content={seo.description} />
            <meta name="image" content={seo.image && seo.image} />

            <meta property="og:title" content={seo.title} />
            <meta property="og:description" content={seo.description} />
            <meta property="og:image" content={seo.image && seo.image} />
            <meta property="og:url" content={seo.url} />

            <meta name="twitter:title" content={seo.title} />
            <meta name="twitter:description" content={seo.description} />
            <meta name="twitter:image" content={seo.image && seo.image} />
        </Helmet>
    );
};

export default SEO;

const query = graphql`
    query SEO {
        site {
            siteMetadata {
                defaultTitle: title
                titleTemplate
                defaultDescription: description
                url
            }
        }
    }
`;
