import clsx from 'clsx';
import React from 'react';

export interface GridProps extends React.HTMLAttributes<HTMLDivElement> {
    base?: boolean;
    container?: boolean;
    defaultGap?: boolean;
    item?: boolean;
}

const Grid = React.forwardRef<HTMLDivElement, GridProps>(
    (
        { base, children, className, container, defaultGap, item, ...props },
        ref
    ) => {
        const classes = clsx(
            {
                grid: container,
                't-sm:grid-cols-12': base && container,
                'gap-x-2 gap-y-5': container && defaultGap
            },
            className
        );

        return (
            <div className={classes} ref={ref} {...props}>
                {children}
            </div>
        );
    }
);

Grid.defaultProps = {
    base: true,
    container: false,
    defaultGap: true
};

export default Grid;
