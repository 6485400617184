import React, { forwardRef } from 'react';
import clsx from 'clsx';

export interface NavbarProps extends React.HTMLAttributes<HTMLElement> {
    color?: 'primary' | 'default';
}

const Navbar = forwardRef<HTMLElement, NavbarProps>(
    ({ children, className, ...props }, ref) => {
        return (
            <nav
                className={clsx(
                    'flex items-center justify-between fixed top-0 left-0 right-0 z-50 px-5 py-3 bg-primary',
                    className
                )}
                ref={ref}
                {...props}
            >
                {children}
            </nav>
        );
    }
);

Navbar.defaultProps = {
    color: 'primary'
};

export default Navbar;
