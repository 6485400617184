import React, { forwardRef } from 'react';
import clsx from 'clsx';
import './styles.css';

export interface BurgerButtonProps
    extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    mobileOnly?: boolean;
}

const BurgerButton = forwardRef<HTMLButtonElement, BurgerButtonProps>(
    ({ children, className, mobileOnly, ...props }, ref) => {
        return (
            <button
                className={clsx(
                    'burger-button',
                    'flex items-center justify-center w-24px relative',
                    { 'l-sm:hidden': mobileOnly },
                    className
                )}
                ref={ref}
                {...props}
            >
                <div />
                <div />
                <div />
            </button>
        );
    }
);

BurgerButton.defaultProps = {
    mobileOnly: true
};

export default BurgerButton;
