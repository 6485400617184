// @ts-nocheck
import clsx from 'clsx';
import { Link as GatsbyLink } from 'gatsby';
import React, { FC, useEffect, useRef } from 'react';

import SvgIcon from '../../svg-icon/svg-icon';
import { AnchorLink } from '../anchor-link';
import * as style from './style.module.css';

export interface ButtonProps
    extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
    anchor?: string;
    button?: boolean;
    arrowFlipped?: boolean;
    color?: 'secondary';
    disabled?: boolean;
    href?: string;
    onProductShowcase?: boolean;
    partiallyActive?: any;
    to?: string;
    variant?: 'contained' | 'text' | 'signage' | 'signage-with-label';
}

const styles = {
    base:
        'inline-flex items-center text-xs font-medium uppercase tracking-wider relative z-10 cursor-pointer',
    variant: {
        contained:
            'button-contained px-2.5 py-1.5 rounded-full overflow-hidden',
        signage:
            'button-contained w-7 h-7 min-w-5 min-h-5 flex items-center justify-center rounded-full overflow-hidden',
        'signage-with-label':
            'button-contained flex flex-col items-center text-secondary',
        text: style.buttonText
    },
    color: {
        secondary: {
            contained: 'bg-secondary text-white',
            signage: 'bg-secondary text-white',
            'signage-with-label': '',
            text: 'text-secondary'
        }
    }
};

const Button: FC<ButtonProps> = ({
    anchor,
    button,
    arrowFlipped,
    children,
    className,
    color,
    disabled,
    href,
    onProductShowcase,
    partiallyActive,
    to,
    variant,
    ...props
}) => {
    const internal = /^\/(?!\/)/.test(to);
    const buttonRef = useRef(null);

    useEffect(() => {
        const button = buttonRef.current;
        const buttonCircle = button.querySelector(`.${style.circle}`);

        const handleMouseEvent = (event, circle) => {
            const relX = event.offsetX;
            let relY = event.offsetY;
            circle.style.left = `${relX}px`;
            circle.style.top = `${relY}px`;
        };

        button.addEventListener('mouseenter', (e) => {
            if (buttonCircle) {
                handleMouseEvent(e, buttonCircle);
                buttonCircle.classList.remove(style.displode);
                buttonCircle.classList.add(style.explode);
            }
            if (onProductShowcase) {
                const productShowcaseCircle = button.parentElement.querySelector(
                    '.product-showcase-circle'
                );
                productShowcaseCircle.classList.add('active');
            }
        });
        button.addEventListener('mouseleave', (e) => {
            if (buttonCircle) {
                handleMouseEvent(e, buttonCircle);
                buttonCircle.classList.remove(style.explode);
                buttonCircle.classList.add(style.displode);
            }
            if (onProductShowcase) {
                const productShowcaseCircle = button.parentElement.querySelector(
                    '.product-showcase-circle'
                );
                productShowcaseCircle.classList.remove('active');
            }
        });
    }, []);

    if (internal) {
        return (
            <GatsbyLink
                to={to}
                activeClassName="active"
                partiallyActive={partiallyActive}
                className={clsx(
                    styles.base,
                    styles.variant[variant],
                    styles.color[color][variant],
                    { 'button-on-procuct-showcase': onProductShowcase },
                    className
                )}
                ref={buttonRef}
                {...props}>
                {variant !== 'text' && variant !== 'signage-with-label' && (
                    <div className={style.circle} />
                )}

                {variant === 'signage' && (
                    <SvgIcon
                        icon="arrow-right"
                        color="white"
                        className={clsx('h-3', {
                            'transform -scale-x-1': arrowFlipped
                        })}
                    />
                )}

                {variant === 'signage-with-label' && (
                    <>
                        <div className="w-7 h-7 min-w-5 min-h-5 flex items-center justify-center rounded-full overflow-hidden relative z-10 bg-secondary">
                            <div className={style.circle} />
                            <SvgIcon
                                icon="arrow-right"
                                color="white"
                                className={clsx('h-3', {
                                    'transform -scale-x-1': arrowFlipped
                                })}
                            />
                        </div>
                        <span className="mt-1">{children}</span>
                    </>
                )}

                {variant !== 'signage' && variant !== 'signage-with-label' && (
                    <>
                        <span>{children}</span>
                        <SvgIcon
                            icon="arrow-right-circle"
                            size={20}
                            className="ml-1.5 fill-current"
                        />
                    </>
                )}
            </GatsbyLink>
        );
    } else if (anchor) {
        return (
            <AnchorLink
                anchor={anchor}
                className={clsx(
                    styles.base,
                    styles.variant[variant],
                    styles.color[color][variant],
                    { 'button-on-procuct-showcase': onProductShowcase },
                    className
                )}
                ref={buttonRef}
                {...props}>
                {variant !== 'text' && variant !== 'signage-with-label' && (
                    <div className={style.circle} />
                )}

                {variant === 'signage' && (
                    <SvgIcon
                        icon="arrow-right"
                        color="white"
                        className={clsx('h-3', {
                            'transform -scale-x-1': arrowFlipped
                        })}
                    />
                )}

                {variant === 'signage-with-label' && (
                    <>
                        <div className="w-7 h-7 min-w-5 min-h-5 flex items-center justify-center rounded-full overflow-hidden relative z-10 bg-secondary">
                            <div className={style.circle} />
                            <SvgIcon
                                icon="arrow-right"
                                color="white"
                                className={clsx('h-3', {
                                    'transform -scale-x-1': arrowFlipped
                                })}
                            />
                        </div>
                        <span className="mt-1">{children}</span>
                    </>
                )}

                {variant !== 'signage' && variant !== 'signage-with-label' && (
                    <>
                        <span>{children}</span>
                        <SvgIcon
                            icon="arrow-right-circle"
                            size={20}
                            className="ml-1.5 fill-current"
                        />
                    </>
                )}
            </AnchorLink>
        );
    } else if (button) {
        return (
            <button
                className={clsx(
                    styles.base,
                    styles.variant[variant],
                    styles.color[color][variant],
                    { 'button-on-procuct-showcase': onProductShowcase },
                    className
                )}
                ref={buttonRef}
                disabled={disabled}
                {...props}>
                {variant !== 'text' && variant !== 'signage-with-label' && (
                    <div className={style.circle} />
                )}

                {variant === 'signage' && (
                    <SvgIcon
                        icon="arrow-right"
                        color="white"
                        className={clsx('h-3', {
                            'transform -scale-x-1': arrowFlipped
                        })}
                    />
                )}

                {variant === 'signage-with-label' && (
                    <>
                        <div
                            className={clsx(
                                'w-7 h-7 min-w-5 min-h-5 flex items-center justify-center relative z-10 ',
                                'bg-secondary rounded-full overflow-hidden'
                            )}>
                            <div className={style.circle} />
                            <SvgIcon
                                icon="arrow-right"
                                color="white"
                                className={clsx('h-3', {
                                    'transform -scale-x-1': arrowFlipped
                                })}
                            />
                        </div>
                        <span className={clsx('mt-1')}>{children}</span>
                    </>
                )}

                {variant !== 'signage' && variant !== 'signage-with-label' && (
                    <>
                        <span>{children}</span>
                        <SvgIcon
                            icon="arrow-right-circle"
                            size={20}
                            className="ml-1.5 fill-current"
                        />
                    </>
                )}
            </button>
        );
    }
    return (
        <a
            href={to}
            className={clsx(
                styles.base,
                styles.variant[variant],
                styles.color[color][variant],
                { 'button-on-procuct-showcase': onProductShowcase },
                className
            )}
            ref={buttonRef}
            {...props}>
            {variant !== 'text' && variant !== 'signage-with-label' && (
                <div className={style.circle} />
            )}

            {variant === 'signage' && (
                <SvgIcon
                    icon="arrow-right"
                    color="white"
                    className={clsx('h-3', {
                        'transform -scale-x-1': arrowFlipped
                    })}
                />
            )}

            {variant === 'signage-with-label' && (
                <>
                    <div className="w-7 h-7 min-w-5 min-h-5 flex items-center justify-center rounded-full overflow-hidden relative z-10 bg-secondary">
                        <div className={style.circle} />
                        <SvgIcon
                            icon="arrow-right"
                            color="white"
                            className={clsx('h-3', {
                                'transform -scale-x-1': arrowFlipped
                            })}
                        />
                    </div>
                    <span className="mt-1">{children}</span>
                </>
            )}

            {variant !== 'signage' && variant !== 'signage-with-label' && (
                <>
                    <span>{children}</span>
                    <SvgIcon
                        icon="arrow-right-circle"
                        size={20}
                        className="ml-1.5 fill-current"
                    />
                </>
            )}
        </a>
    );
};

Button.defaultProps = {
    arrowFlipped: false,
    button: false,
    color: 'secondary',
    disabled: false,
    onProductShowcase: false,
    variant: 'contained'
};

export default Button;
