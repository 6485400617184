import clsx from 'clsx';
import React, { forwardRef } from 'react';

const handleClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    anchor: string
) => {
    e.preventDefault();
    const anchorEl = document.querySelector<HTMLElement>(`#${anchor}`)!;
    const header = document.querySelector<HTMLElement>('.header > nav')!;
    const rect = anchorEl.getBoundingClientRect();
    const top = rect.top - header.offsetHeight + window.pageYOffset;

    window.scrollTo({ top: top, behavior: 'smooth' });
};

interface AnchorLinkProps
    extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
    anchor: string;
}

const AnchorLink = forwardRef<HTMLAnchorElement, AnchorLinkProps>(
    ({ anchor, children, className, ...props }, ref) => {
        return (
            <a
                href=""
                className={clsx(className)}
                onClick={(e) => {
                    handleClick(e, anchor);
                }}
                ref={ref}
                {...props}>
                {children}
            </a>
        );
    }
);

export default AnchorLink;
