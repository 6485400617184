import React, { forwardRef } from 'react';
import clsx from 'clsx';
import './styles.css';

export interface MegaMenuProps extends React.HTMLAttributes<HTMLDivElement> {}

const MegaMenu = forwardRef<HTMLDivElement, MegaMenuProps>(
    ({ children, className, ...props }, ref) => {
        return (
            <div
                className={clsx(
                    'mega-menu',
                    'l-sm:mt-0 l-sm:absolute l-sm:top-full',
                    className
                )}
                ref={ref}
                {...props}
            >
                {children}
            </div>
        );
    }
);

MegaMenu.defaultProps = {};

export default MegaMenu;
